// Bootstrap
// Note this has to be placed before the YAT colour stuff as $theme-colors overrides Bootstrap's color palette
@import "bootstrap/scss/bootstrap";

// Default theme colors
$theme-colors: (
	"coolblack": #090a0b,
	"spacegrey": #353535,
	"snowwhite": #ffffff,
);

// Default brand colors
$brand-colors: (
	"orangered": #ff5100,
	"greatgold": #f2cb05,
	"greenblue": #389092,
);

$theme-name: "";
$brand-name: "#060";
$theme-color: map-get($theme-colors, "snowwhite");
$brand-color: map-get($brand-colors, "orangered");

@if map-has-key($theme-colors, $theme-name) {
	$theme-color: map-get($theme-colors, $theme-name);
} @else if str-index($theme-name, "#") == 1 {
	$theme-color: #ffffff;
}

@if map-has-key($brand-colors, $brand-name) {
	$brand-color: map-get($brand-colors, $brand-name);
} @else if str-index($brand-name, "#") == 1 {
	$brand-color: #060;
}

@import "yat";

// Custom CSS imports
@import "header";
@import "banner";
@import "page";
@import "crews";
@import "committee";
@import "bookings";
@import "socials";
@import "post";
@import "footer";
