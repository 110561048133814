// Social icons for /contact
.social-icons {
	text-align: center;
}

.bi-social {
	font-size: 36px;
}

// Social feeds for /
@media only screen and (min-width: 640px) {
	.embedsocial-hashtag {
		width: calc(50% - 20px);
		float: right;
	}
}

@media only screen and (max-width: 640px) {
	.embedsocial-hashtag {
		width: 100%;
		display: block;
	}
}

.embedsocial-hashtag {
	height: 500px;
	overflow: auto;
}
