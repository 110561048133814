// Reduce padding at top of YAT pages
.page-content {
	padding-top: 24px;
}

// Increase max width of YAT pages
.wrapper {
	max-width: 1200px;
}

@media only screen and (min-width: 800px) {
	.wrapper {
		width: 90%;
	}
}

// Generic image with caption
.page-content figure {
	max-width: 500px;
	display: block;
	margin: auto;
	text-align: center;
}

// Reduce line spacing on committee/crew lists
.accordion-body p {
    margin-bottom: 2px;
}

body {
	font-size: 16px;
}

.page-content a {
	font-weight: bold;
}
