// Reduce height of YAT banner image
@media only screen and (min-width: 992px) {
	.page-banner {
		height: 480px;
	}
}

@media only screen and (max-width: 991px) {
	.page-banner {
		height: 240px;
	}
}

// Make heading more visible over light images
.page-banner .page-banner-heading {
	text-shadow: 1px 1px 5px #000a;
}

.page-banner .page-banner-inner {
	top: 100%;
	transform: translateY(-120%);
}
