// Mobile (image only)
@media only screen and (max-width: 800px) {
    .committee-img {
        display: none;
    }

    .committee-list {
        display: block;
        padding: 20px;
    }
}

// Desktop (image and text)
@media only screen and (min-width: 800px) {
    .committee-img, .committee-list {
        padding: 20px;
    }

    .committee-img {
        width: 50%;
    }

    .committee-list {
        float:right;
        width: 50%;
    }
}
