// Add spacing between current crew images
.current-crews img {
    padding: 10px;
}

// Ensure columns are half-width even if only one is shown
.current-crews .col {
    max-width: 50%;
}

// Make columns full-width on mobile
@media only screen and (max-width: 800px) {
    .current-crews .col, .past-crews .col {
        min-width: 100%;
    }
}

.crew-photo {
    max-width: 80%;
    padding: 5px;
}
