// Remove "NEW" tags from news page
.page-segments li a:after {
	display: none;
}

// Float post images right on desktop
@media only screen and (min-width: 800px) {
	.img-left, .img-right {
		max-width: 40%;
		margin-left: 20px !important;
		margin-bottom: 20px;
	}

	.img-left {
		display: inline-block !important;
	}

	.img-right {
		float: right;
	}
}

// Keep images central but limit height on mobile
@media only screen and (max-width: 800px) {
	img.img-left, img.img-right, figure.img-left img, figure.img-right img {
		max-height: 300px;
	}

	figure.img-left, figure.img-right {
		margin-bottom: 10px;
	}
}
